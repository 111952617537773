<template>
  <div class="contrato">
    <header class="contrato-header">
      <img class="contrato-header-wave" src="@/assets/img/wave_3blue_rgb.png" alt="">

      <div class="contrato-header-logo">
        <!-- <img src="@/assets/img/logo-ats.svg" alt=""> -->
      </div>
    </header>

    <main class="contrato-container">
      <article class="contrato-article">
        <header class="contrato-article-header">
          <div class="contrato-article-header-logo">
            <!-- <img src="@/assets/img/logo-ats-azul.svg" alt=""> -->
          </div>

          <div class="contrato-article-header-cabecalho">
            {{ date }}
          </div>
        </header>

        <div class="contrato-article-wrapper" v-on:scroll="handleScroll">
          {{ termoAtual !== null ? termoAtual.term : 'Não possui termo'}}
        
          <h1>TERMO DE ACEITE E CONSENTIMENTO PARA USO DA PLATAFORMA TROCO SOLIDÁRIO</h1>
          
          <p>Este Termo de Aceite e Consentimento (“Termo”) regem o acesso e o uso da Plataforma Troco Solidário (“Plataforma” ou “Serviço”), disponibilizado e mantido por A.T.S - Associação Troco Solidário, associação privada, inscrita no CNPJ sob o nº. 36.289.085/0001-17, com sede à ST SCN, QUADRA 04, BLOCO B, EDIF CENTRO EMP. VARIG SALA 1201, Bairro Asa Norte, Brasília, Distrito Federal, CEP:70.714-900 (“Empresa”, “Troco Solidário”, “nós”).</p>
          
          <p>O acesso à Plataforma pode ser feito por meio do endereço <a href="https://gestao.trocosolidario.org">https://gestao.trocosolidario.org</a> (“Plataforma de Gestão do Troco Solidário”).</p>

          <p><strong>O presente Termo</strong> está disponível para leitura e consulta pelo Usuário por meio do link https://gestao.trocosolidario.org/termo-consentimento, <strong>pedimos que leia com atenção este Termo antes de começar a usar a Plataforma de Gestão e preencher o cadastro.</strong></p>

          <p>Ao acessar e usar os Serviços você concorda com os presentes termos e condições, que estabelecem o relacionamento contratual entre você, na qualidade de usuário(a), e a ATS.</p>

          <p>Se você não concorda com estes Termos, você não pode acessar nem usar os Serviços. Mediante referido acesso e uso, estes Termos imediatamente encerram, substituem e superam todos os acordos, Termos e acertos anteriores entre você e qualquer Afiliada da ATS. A ATS poderá imediatamente encerrar estes Termos ou quaisquer Serviços em relação a você ou, de modo geral, deixar de oferecer ou negar acesso aos Serviços ou a qualquer parte deles, a qualquer momento e por qualquer motivo.</p>

          <p>A ATS poderá alterar os Termos relativos aos Serviços a qualquer momento. Aditamentos entrarão em vigor quando a ATS fizer a postagem da versão atualizada dos Termos neste local ou das condições atualizadas ou Termos adicionais sobre o respectivo Serviço. O fato de você continuar a acessar ou usar os Serviços após essa postagem representa seu consentimento em vincular-se aos Termos alterados.</p>


          <dl>
            <dt><b>1.</b> <strong>Definições</strong> - Para os fins deste Termo, as seguintes expressões terão o seguinte significado:</dt>
            <dd><b>1.1.</b> <strong>Conta:</strong> conta de acesso da Instituição Parceira, criada a partir de CNPJ ou e-mail e senha.</dd>
            <dd><b>1.2.</b> <strong>Nós, A.T.S</strong> - Associação Troco Solidário: é uma organização social voltada a apoiar e fortalecer outras instituições sociais, por meio do Troco Solidário ou outra empresa a quem venha a ser confiada, pela A.T.S, a administração da Plataforma. O objetivo da ATS é viabilizar a destinação do troco oriundo das transações comerciais havidas nas EMPRESAS CREDENCIADAS à respectiva Instituição Parceira, permitindo que o USUÁRIO possa contribuir com campanhas e projetos sociais de uma forma segura, legal, prática e rápida.</dd>
            <dd><b>1.3</b> <strong>Instituição Parceira:</strong> uma entidade assistencial sem fins lucrativos, que atenda as condições previstas neste termo.</dd>
            <dd><b>1.4.</b> <strong>Usuário:</strong> pessoa que acessa a Plataforma.</dd>
            <dd><b>1.5.</b> <strong>Pontos de Arrecadação:</strong> Estabelecimentos que fazem a captação dos valores doados pelos clientes e usuários da plataforma Troco Solidário;</dd>
            <dd><b>1.6.</b> <strong>Doador ou Cliente:</strong> qualquer pessoa que possa e queira realizar uma doação a uma determinada instituição através dos pontos autorizados de arrecadação ou diretamente pela plataforma.</dd>

            <dt><b>2.</b> <strong>Elegibilidade -</strong> Todas as Instituições Parceiras que participem do Programa Troco Solidário serão identificadas genericamente como "Instituição Parceira". Para participar do Programa, será necessário cumprir todos os requisitos de elegibilidade, previstos em lei e nos termos a seguir descritos:</dt>
            <dd><b>2.1.</b> Não são passíveis de qualificação como Intuição Parceira:</dd>
              <ol type="I" class="nivel1">
                <li>as sociedades comerciais;</li>
                <li>os sindicatos, as associações de classe ou de representação de categoria profissional;</li>
                <li>as instituições religiosas ou voltadas para a disseminação de credos, cultos, práticas e visões devocionais e confessionais;</li>
                <li>as organizações partidárias e assemelhadas, inclusive suas fundações;</li>
                <li>as entidades de benefício mútuo destinadas a proporcionar bens ou serviços a um círculo restrito de associados ou sócios;</li>
                <li>as entidades e empresas que comercializam planos de saúde e assemelhados;</li>
                <li>as instituições hospitalares privadas não gratuitas;</li>
                <li>as escolas privadas dedicadas ao ensino formal não gratuito;</li>
                <li>as organizações sociais;</li>
                <li>as cooperativas;</li>
                <li>as fundações públicas;</li>
                <li>as fundações, sociedades civis ou associações de direito privado criadas por órgão público ou por fundações públicas;</li>
              </ol>
            
            <dd><b>2.2.</b> Somente será conferido o título de Instituição Parceira às pessoas jurídicas de direito privado, sem fins lucrativos, cujos objetivos sociais tenham pelo menos uma das seguintes finalidades:</dd>
            <ol type="I" class="nivel1">
              <li>promoção da assistência social;</li>
              <li>promoção da cultura, defesa e conservação do patrimônio histórico e artístico;</li>
              <li>promoção gratuita da educação, observando-se a forma complementar de participação das organizações de que trata esta Lei;</li>
              <li>promoção gratuita da saúde;</li>
              <li>promoção da segurança alimentar e nutricional;</li>
              <li>defesa, preservação e conservação do meio ambiente e promoção do desenvolvimento sustentável;</li>
              <li>promoção do voluntariado;</li>
              <li>promoção do desenvolvimento econômico e social e combate à pobreza;</li>
              <li>experimentação, não lucrativa, de novos modelos sócio-produtivos e de sistemas alternativos de produção, comércio, emprego e crédito;</li>
              <li>promoção de direitos estabelecidos, construção de novos direitos e assessoria jurídica gratuita de interesse suplementar;</li>
              <li>promoção da ética, da paz, da cidadania, dos direitos humanos, da democracia e de outros valores universais;</li>
              <li>estudos e pesquisas, desenvolvimento de tecnologias alternativas, produção e divulgação de informações e conhecimentos técnicos e científicos que digam respeito às atividades mencionadas neste artigo.</li>
              <li>estudos e pesquisas para o desenvolvimento, a disponibilização e a implementação de tecnologias voltadas à mobilidade de pessoas, por qualquer meio de transporte.</li>
            </ol>
            <dd><b>2.3.</b> As Instituições interessadas em se tornar Parceiras do Troco Solidário deveram, sob pena de desligamento:</dd>
            <ol type="I" class="nivel1">
              <li>observar os princípios da legalidade, impessoalidade, moralidade, publicidade, economicidade e da eficiência;</li>
              <li>adotar práticas de gestão administrativa, necessárias e suficientes a coibir a obtenção, de forma individual ou coletiva, de benefícios ou vantagens pessoais;</li>
              <li>constituir conselho fiscal ou órgão equivalente, dotado de competência para opinar sobre os relatórios de desempenho financeiro e contábil, e sobre as operações patrimoniais realizadas, emitindo pareceres quando solicitados;</li>
              <li>as normas de prestação de contas a serem observadas pela entidade, que determinarão, no mínimo:
                <dd><span>a)</span> a observância dos princípios fundamentais de contabilidade e das Normas Brasileiras de Contabilidade;</dd>
                <dd><span>b)</span> a apresentar no encerramento do exercício fiscal, o relatório de atividades e das demonstrações financeiras da entidade, incluindo-se as certidões negativas de débitos junto ao INSS e ao FGTS, colocando-os à disposição para exame de qualquer cidadão;</dd>
                <dd><span>c)</span> a realização de auditoria, inclusive por auditores externos independentes se for o caso, da aplicação dos eventuais recursos objeto do termo da parceria;</dd>
                <dd><span>d)</span> a prestação de contas de todos os recursos e bens recebidos por meio da parceria.</dd>
              </li>
            </ol>

            <dd><b>2.4.</b> Cumprido os requisitos anteriores, a pessoa jurídica de direito privado sem fins lucrativos, interessada em obter a qualificação de Instituição Parceira, deverá preencher o requerimento presente nessa Plataforma e enviar para o e-mail contato@trocosolidario.org cópias dos seguintes documentos:</dd>
            <ol type="I" class="nivel1">
              <li>estatuto registrado em cartório;</li>
              <li>ata de eleição de sua atual diretoria;</li>
              <li>balanço patrimonial e demonstração do resultado do exercício;</li>
              <li>declaração de isenção do imposto de renda;</li>
              <li>inscrição no Cadastro Geral de Contribuintes.</li>
            </ol>
            <dd><b>2.5.</b> O pedido de qualificação somente será indeferido a requerente não atender os requisitos deste termo ou a documentação apresentada estiver incompleta.</dd>

            <dt><b>3.</b> <strong>Serviço.</strong> As funcionalidades fornecidas pela Plataforma Troco Solidário que consiste em viabilizar a destinação do troco oriundo das transações comerciais havidas nas EMPRESAS CREDENCIADAS à respectiva <strong>INSTITUIÇÃO PARCEIRA,</strong> permitindo, assim, que o USUÁRIO possa contribuir com campanhas e projetos sociais. Após o cadastro do usuário na plataforma: Será criada uma Conta Virtual de Créditos e uma Conta Virtual de Prêmios. Doações realizadas através dos pontos de arrecadação e validadas na plataforma bem como doações por meio de pagamento PAGSEGURO, PICPAY, ficaram disponíveis ao usuário através e sua conta virtual de créditos. Todos os prêmios ganhos pelo usuário estarão disponíveis na conta virtual de prêmios e podem ser resgatados a qualquer momento.</dt>

            <dt><b>4.</b> <strong>Programa Troco Solidário.</strong> O Programa Troco Solidário visa estimular a doação para as Instituições Parceiras.</dt>
            <dd><b>4.1.</b> Ao realizar a doação para o programa Troco Solidário no estabelecimento comercial o doador receberá um código QR contendo o nome do estabelecimento, hora da doação e o valor doado.</dd>
            <dd><b>4.2.</b> O doador poderá cadastrar-se no <a href="https://cupons.trocosolidario.org" target="_blank">https://cupons.trocosolidario.org</a> e ao fazer a leitura do código QR, receberá créditos do mesmo valor doado e adquirir cupons das modalidades de premiações.</dd>
            <dd><b>4.3.</b> O doador que já for cadastrado na Plataforma poderá informar seu CPF no estabelecimento comercial e sua doação será creditada automaticamente na plataforma Troco Solidário.</dd>
            <dd style="color: red;"><b>4.4.</b> Cada estabelecimento comercial credenciado na plataforma deverá informar qual das Instituições Parceiras serão beneficiadas com as doações.</dd>
            <dd style="color: red;"><b>4.5.</b> A Instituição Parceira ao aderir ao Programa Troco Solidário concorda que 90% (noventa por cento) das doações realizadas serão destinadas ao fomento do programa e 10% (dez por cento) serão creditados em sua conta automaticamente pela ATS, através de Split na plataforma.</dd>
            <dd style="color: red;"><b>4.6.</b> A Instituição Parceira receberá ainda 2% sobre a arrecadação proveniente dos pontos comerciais em que fizer o credenciamento e manutenção.</dd>
            <dd><b>4.7.</b> A ATS será responsável por toda operação do Programa Troco Solidário como por exemplo: arrecadar recursos, comissionamentos, premiações, impostos, marketing e custos operacionais.</dd>
            <dd><b>4.8.</b> A ATS poderá alterar ou encerrar o Programa, ou qualquer parte dele, em qualquer momento, ou excluir a da Instituição parceira, caso haja qualquer descumprimento do presente termo ou a manutenção da Instituição Parceira seja prejudicial ao Programa.</dd>
            <dd><b>4.9.</b> Inexiste qualquer vínculo empregatício entre a ATS e os empregados da Instituição Parceira, assim como inexiste qualquer relação de subordinação pessoal e de exclusividade, assumindo cada qual, as obrigações trabalhistas e previdenciárias, bem como respondendo isoladamente por eventuais reclamações trabalhistas ajuizadas por seus preposto ou empregados. </dd>

            <dt><b>5.</b> <strong>Obrigações</strong></dt>
            <dd><b>5.1.</b> Obrigações da ATS – Disponibilizar plataforma tecnológica, framework, logomarcas, premiações em prol do projeto Troco Solidário, e auxiliar na busca redes de estabelecimentos credenciados, apoiadores e influenciadores.</dd>
            <dd clas="nivel3">
              <b>5.1.1.</b>
              A ATS compromete-se a: (i) fornecer o Serviço do modo mais constante e adequado possível; (ii) notificar a INSTITUIÇÃO PARCEIRA  em caso de mudanças no Serviço ou nos Termos; (iii) oferecer ferramentas de controle dos dados fornecidos; (iv) executar e interpretar o presente contrato em boa-fé.
            </dd>

            <dd><b>5.2.</b> Obrigações da INSTITUIÇÃO PARCEIRA. A Instituição Parceira, ao concordar com estes Termos, concorda em: (i) utilizar o Serviço oferecido com responsabilidade e boa-fé, dentro dos limites estabelecidos por estes Termos e das funcionalidades associadas às Plataformas; (ii) criar e manter a salvo sua senha de acesso; (iii) manter suas informações de contato e seus demais dados atualizados e corretos; (iv) notificar a Troco Solidário sobre quaisquer alterações cadastrais ou desativação da conta.</dd>

            <dd><b>5.3.</b> Instituição Parceira fica proibida, sob pena de suspensão ou exclusão do Programa, de: (i) utilizar o Serviço para finalidades ilícitas; (ii) submeter ao Serviço, falsamente, dados de terceiros; (iii) submeter ao Serviço dados protegidos por direitos autorais dos quais não possa dispor; (iv) submeter ao Serviço qualquer tipo de conteúdo ilegal, imoral ou incompatível com o Serviço, tal como: material preconceituoso, racista, ofensivo, injurioso, difamatório, calunioso, pornográfico, relacionado a menores de idade em qualquer aspecto e em especial pornografia ou exploração infantil, além de qualquer informação, ferramenta, software ou conhecimento que permita a um usuário ou terceiro violar, de qualquer forma, estes Termos. Igual tratamento será concedido a qualquer tipo de arquivo, link, mídia ou informação inserida de forma criptografada, protegida por senha ou de outro modo não imediatamente identificado como material ilícito.</dd>

            <dd><b>5.4.</b> A Instituição Parceira é titular de todo o conteúdo que inserir no Serviço, sob qualquer forma. O Troco Solidário declara que não realiza e não está obrigado a realizar controle prévio dos dados submetido ao Serviço.</dd>

            <dd><b>5.5.</b> A Instituição Parceira fica proibida, igualmente e sob as mesmas penas, de: modificar, apagar, sabotar ou de qualquer forma violar, ou auxiliar, incentivar ou facilitar a terceiro a violação, do Serviço, seja o componente web, os Aplicativos ou o serviço conectado subjacente; violar conteúdo protegido por direitos de propriedade intelectual da Troco Solidário ou de qualquer afiliado, subcontratado ou parceiro; não buscar, abordar ou aceitar abordagem de terceiro para, de qualquer forma utilizar o Serviço ou qualquer de seus componentes para fraudar os presentes Termos e/ou violar a Lei, ou com a intenção de incitar terceiro a isso.</dd>

            <dt style="color: red;">
              <b>6.</b> 
              <strong>Aceite ao Termo.</strong> Ao acessar a Plataforma com seu login e senha previamente cadastrados pela ATS e ler “Li e concordo com este Termo” e clicando no botão de aceite, a Instituição Parceira adere aos termos deste contrato, de forma livre, expressa, consciente e informada, declarando, ainda, que teve acesso a este documento e que leu e concorda com as condições deste. Você não pode e não deve acessar o Serviço, usar as Plataformas Troco Solidário se não concordar com este Termo, e caso discorde de qualquer termo aqui mencionado, deve cancelar o procedimento de abster-se de utilizar a Plataforma.
            </dt>
            <dd>
              <b>6.1.</b> 
              Revogação do consentimento. Você compreende que o consentimento dado acima pode ser revogado por você a qualquer momento, mediante requerimento encaminhado a nós, dentro da Plataforma, o que acarretará a exclusão dos dados e informações eventualmente coletados pelo uso da Plataforma, com exceção daqueles cuja coleta e tratamento são feitos com outra base legal que não o consentimento e daqueles que somos legalmente obrigados a manter.
            </dd>

            <dt>
              <b>7.</b> 
              <strong>Política de Privacidade.</strong> A Política de Privacidade é parte integrante destes Termos, e pode ser acessada pela Instituição Parceira a qualquer momento pelos Aplicativos ou no site <a href="https://www.trocosolidario.org/politica-privacidade" target="_blank">https://www.trocosolidario.org/politica-privacidade.</a> Essa Política detalha quais dados são coletados pelas Plataformas, as finalidades para as quais os dados são utilizados, seus direitos como titular desses dados, medidas de segurança, contato direto para solicitações sobre dados pessoais, dentre outras informações importantes. Ao aceitar os presentes Termos você declara que teve acesso e tem conhecimento de todas as informações presentes na Política de Privacidade, fornecendo seu consentimento nas hipóteses e condições descritas naquele documento.
            </dt>

            <dt>
              <b>8.</b>
              <strong>Propriedade intelectual.</strong> Programas de computador, arquivos, textos, ícones, desenhos, vídeos, sons, marcas, logotipos, layouts, templates, invenções, modelos de utilidade, desenho industrial, obras artísticas, científicas ou literárias e todos os materiais, obras, planos e objetos, em qualquer formato ou suporte físico, que sejam passíveis de proteção com base na Lei de Propriedade Industrial (Lei nº 9.279/1996), na Lei de Direito Autoral (Lei nº 9.610/1998), na Lei de Software (Lei nº 9.609/1998), em convenções internacionais de que o Brasil seja parte ou em leis nacionais dos países em que a Empresa atue e disponibilize acesso à Plataforma, que sejam de titularidade, posse, domínio do Troco Solidário, ou que sejam por ela utilizados na criação, fornecimento e manutenção da Plataforma, estejam eles registrados, patenteados, com pedido de registro ou depósito pendente, ou não, são considerados como propriedade intelectual.
            </dt>
            
            <dt>
              <b>9.</b>
              <strong>Exceções de garantias.</strong> Na Plataforma como um todo são oferecidos serviços tal como se encontram, não garantindo o Troco Solidário que o Serviço seja considerado apto ou adequado para qualquer finalidade que seja, utilizando-se o usuário do Serviço por sua conta e risco.
            </dt>
            <dd><b>9.1.</b> <strong>Serviços de terceiros.</strong> Ao utilizar as Plataformas, você tem ciência de que tal uso depende ou pode depender da contratação de serviços de terceiros, tal como acesso à rede de internet, telefonia móvel, entre outros, sendo que a Troco Solidário não garante que tais serviços sejam adequados ou suficientes para possibilitar o uso sem falhas ou defeitos da Plataforma.</dd>
            <dd><b>9.2.</b> <strong>Segurança e erros.</strong> A Plataforma foi desenvolvida segundo os padrões do mercado de qualidade, segurança e confidencialidade, buscando o Troco Solidário sempre cumprir os mais altos padrões de boas práticas no que toca a manutenção do Serviço e a segurança e confidencialidade dos dados nele contidos. Não garantimos, entretanto, que as Plataformas sejam perfeitas ou não estejam sujeitas a falhas de segurança e erros de operação. Você declara que tem ciência disso e que o Troco Solidário não pode ser responsabilizado por tais ocorrências, comprometendo-se o Troco Solidário, por outro lado, a agir sempre com diligência para resolver todo e qualquer problema de segurança, confidencialidade ou confiabilidade da Plataforma em tempo razoável.</dd>
            <dd><b>9.3.</b> <strong>Falhas de dispositivos móveis.</strong> O Troco Solidário não pode garantir a absoluta segurança, integridade e confidencialidade dos dados, pessoais ou não, inseridos nas Plataformas caso os dispositivos móveis ou fixos utilizados para acesso à Plataformas apresentem, por si, vulnerabilidades de segurança, sejam elas acidentais (erros, bugs, etc) ou propositais, sejam elas causadas por terceiros (malware, violação de código, ataques cibernéticos) ou pelo próprio Usuário (por meio de procedimentos que diminuem a segurança do dispositivo, como jailbrake ou obtenção de acesso root).</dd>
            <dd><b>9.4.</b> <strong>Ação de agentes maliciosos.</strong> Você está ciente de que, apesar de toda a segurança que nos esforçarmos em implementar, não há garantia contra a ação de hackers, crackers, ciberativistas e outros agentes maliciosos que podem tentar ou desejem violar o Serviço por diversão ou para ganho próprio ou de terceiros, e você concorda que, nestes casos, não haverá qualquer responsabilidade do Troco Solidário pelos danos daí decorrentes.</dd>
            <dd><b>9.5.</b> <strong>Disponibilidade e Continuidade do Serviço.</strong> O Troco Solidário não garante a disponibilidade contínua do Serviço, estando este sujeito a interrupções por falha ou manutenção, podendo ainda ser suspenso ou cancelado mediante simples notificação prévia com pelo menos 30 (trinta) dias de antecedência.</dd>
            <dd><b>9.6.</b> <strong>Exceções de responsabilidade pelos danos de uso do Serviço.</strong> A ATS, suas afiliadas, subcontratantes e parceiros não são responsáveis por quaisquer danos, sejam eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes, perda de dados, ou qualquer outra que você ou terceiro venha a experimentar direta ou indiretamente relacionados ao uso do Serviço. Você também reconhece que não somos responsáveis por qualquer tipo de danos oriundos de, mas não limitado a: falhas dos equipamentos; vulnerabilidades de segurança nos dispositivos de acesso à Plataforma, danos causados por ações ou omissões do Usuário; utilização indevida dos equipamentos e dispositivos; interrupções ou erros na transmissão de dados pela internet ou telefonia celular; intervenção de qualquer espécie do Poder Público ou ainda força maior ou caso fortuito. O Serviço é ofertado tal como se encontra e o uso feito do Serviço é de responsabilidade do próprio usuário.</dd>
            <dd><b>9.7.</b> <strong>Danos sofridos por interferência de terceiros.</strong> A ATS, suas afiliadas, subcontratantes e parceiros não são responsáveis por quaisquer danos, sejam eles de natureza física, moral, estética, econômica, acidental, punitiva, perda de chance, lucros cessantes, perda de dados, ou qualquer outra que você ou terceiro venham a experimentar em razão da interferência ilegítima de terceiros no Serviço prestado.</dd>
            <dd><b>9.8.</b> <strong>Direito de regresso.</strong> Caso  a ATS seja implicada de qualquer maneira em cobranças extrajudiciais ou qualquer outra medida judicial em decorrência de danos causados por uma Instituição Parceira ou pessoas pelas quais é responsável, está se obriga a intervir nos procedimentos em trâmite, de modo a isentar a ATS de qualquer possível resultado negativo. Ainda que tais esforços não sejam suficientes, a ATS terá direito integral de regresso contra a Instituição Parceira quando o dano a ser indenizado decorra direta ou indiretamente de culpa da Instituição Parceira.</dd>
            <dd><b>9.9.</b> <strong>Acesso a logs.</strong> A ATS não tem a obrigação de fornecer e não fornecerá eventuais logs ou registros de conexão e de acesso à aplicação que não digam respeito a própria Instituição Parceira solicitante, exceto se assim determinado por decisão judicial.</dd>

            <dt>
              <b>10.</b>
              <strong>Rescisão contratual.</strong> Estes Termos, em regra, vigem desde o cadastro realizado pela Instituição Parceira até seu desligamento do Programa, seja ele realizado a pedido de uma das partes, por comum acordo ou por descontinuidade/encerramento do serviço.
            </dt>
            <ol type="a">
              <li><strong>Rescisão por violação contratual ou legal.</strong> Caso a Instituição Parceira viole estes Termos, a Política de Privacidade ou a Lei vigente, a ATS fica autorizada, à sua discrição, a suspender ou cancelar o acesso ao Programa Troco Solidário.</li>
              <li><strong>Rescisão a pedido da Instituição Parceira.</strong> Caso o Usuário solicite o encerramento de sua participação, sua conta será excluída no prazo de 30 dias a contar do pedido, feito por meio da Plataforma do Serviço.></li>
            </ol>

            <dt>
              <b>11.</b> 
              <strong>Modificações.</strong> Nosso serviço pode mudar de tempos em tempos. Por isso, podemos modificar unilateralmente este Termo a qualquer tempo. Sempre que houver uma mudança relevante deste Termo, você será notificado por meio das informações de contato disponibilizadas por você, que devem ser mantidas atualizadas. Na notificação enviada, você terá acesso ao novo texto do Termo. Após tal notificação, ao continuar utilizando o Serviço, você concorda automaticamente com os novos Termos, pois a utilização do Serviço é condicionada a isso. Caso não concorde com as alterações e queira cancelar e apagar sua conta, pedimos que envie um e-mail para contato@trocosolidario.org. A versão atualizada destes Termos estará sempre disponível no endereço: <a href="https://gestao.trocosolidario.org/termo-consentimento" target="_blank">https://gestao.trocosolidario.org/termo-consentimento.</a>
            </dt>

            <dt>
              <b>12.</b> 
              <strong>Disposições finais.</strong> Este contrato é regido pela lei brasileira, sendo esta integralmente aplicável a quaisquer disputas que possam surgir sobre a interpretação ou execução deste contrato, assim como qualquer outra disputa que envolva direta ou indiretamente a prestação dos serviços neste documento descritos.
            </dt>
            <dd>
              <b>12.1.</b> 
              Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento dos termos e condições deste contrato ou de prerrogativas decorrentes dele ou de lei, não constituirão novação ou renúncia, nem afetarão o exercício de quaisquer direitos, que poderão ser plena e integralmente exercidos, a qualquer tempo.
            </dd>
            <dd>
              <b>12.2.</b> 
              Caso se perceba que uma disposição é nula, as disposições restantes do Termo permanecerão em pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto quanto possível.
            </dd>
            <dd>
              <b>12.3.</b> 
              Em todos os casos, esgotado o procedimento anterior sem solução da controvérsia, as partes optam desde já por reconhecer a competência da Justiça Estadual brasileira, elegendo o foro da Comarca de Goiânia, Brasil, e renunciando a qualquer outro, por mais privilegiado que seja.
            </dd>

            <dt>
              <b>13.</b> 
              Caso você tenha dúvidas ou solicitações sobre este Termo, sobre nossas práticas, ou sobre nosso serviço, por favor entre em contato: <a href="https://www.trocosolidario.org/contato" target="_blank">https://www.trocosolidario.org/contato</a>, telefone de contato: 62 9 9464-6282, e-mail para contato: contato@trocosolidario.org.
            </dt>
          </dl>
        </div>

        <div class="text-center mt-5" v-if="showBtn">
          <a href="#" class="contrato-article-btn" @click.prevent="concordoTermo">Li e concordo com este Termo</a>
        </div>
      </article>


      <div class="contrato-footer">
        <span class="contrato-footer-maozinha">
          <img src="@/assets/img/maozinha.png" alt="">
        </span>
        <span class="contrato-footer-fundo">
          <img src="@/assets/img/fundo_rodape.png" alt="">
        </span>
      </div>
    </main>

    <BrFooter />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'
import moment from 'moment'

export default {
  name: 'TermoConsentimento',

  components: {
    BrFooter: () => import('@/views/layout/footer'),
  },

  data: () => ({
    showBtn: false
  }),
  
  methods: {
    ...mapActions('termoConsentimento', ['getTermos', 'addTermos']),
    ...mapActions('auth', ['getUser']),
    handleScroll(e) {
      if (((e.target.scrollHeight - e.target.offsetHeight) - 200) <= e.target.scrollTop)
        this.showBtn = true
    },

    concordoTermo () {
      const self = this
      if (this.user.userEntityIds.length > 0) {
        this.addTermos({ entityId: this.user.userEntityIds[0], agreementId: this.termoAtual.id })
          .then(() => self.$router.push('/auth'))
          .catch(err => errorSnackbar(err.error))

        return
      }

      return errorSnackbar('Usuário nao é o responsável pela entidade')
    }
  },

  mounted () {
    this.getUser()

    if (!this.termoAtual) {
      this.getTermos()
        .catch(err => {
          errorSnackbar(err.error)
          this.$router.push('/home')
        })
    }
  },

  computed: {
    ...mapGetters('termoConsentimento', ['termoAtual']),
    ...mapGetters('auth', ['user']),

    date () {
      moment.locale('pt-BR')
      return  `Goiânia, ${moment().format('DD')} de ${moment().format('MMMM')} de ${moment().format('YYYY')}`
    }
  },
  

}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'helvetica-neue';
  src: url('~@/assets/fonts/helvetica-neue.eot');
  src: url('~@/assets/fonts/helvetica-neue.eot?#iefix') format('embedded-opentype'),
       url('~@/assets/fonts/helvetica-neue.woff2') format('woff2');
  src: url('~@/assets/fonts/HelveticaNeue.svg#HelveticaNeue') format('svg'),
       url('~@/assets/fonts/HelveticaNeue.ttf') format('truetype'),
       url('~@/assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-neue';
  src: url('~@/assets/fonts/helvetica-neue-bold.eot');
  src: url('~@/assets/fonts/helvetica-neue-bold.eot?#iefix') format('embedded-opentype'),
       url('~@/assets/fonts/helvetica-neue-bold.woff2') format('woff2');
  src: url('~@/assets/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg'),
       url('~@/assets/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
       url('~@/assets/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

img {
  max-width: 100%;
}

.contrato {
  font-family: 'helvetica-neue';
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &-wave {
      margin-top: -150px;

      @media (min-width: 1550px) {
        margin-top: -250px;
      }

      @media (max-width: 990px) {
        margin-top: -110px;
      }

      @media (max-width: 815px) {
        margin-top: -60px;
      }

      @media (max-width: 660px) {
        margin-top: 0;
      }
    }

    &-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-60%);
      

      @media (max-width: 1270px) {
        width: 200px;
      }

      @media (max-width: 500px) {
        width: 130px;
      }
    }
  }

  &-container {
    min-height: calc(100vh - 332px);
    // background: url('~@/assets/img/detail_azul_rodape.png') no-repeat top center / cover;
    position: relative;
    padding-bottom: 355px;

    @media (max-width: 1130px) {
      padding-bottom: 240px;
    }

    @media (max-width: 710px) {
      padding-bottom: 130px;
    }

    @media (max-width: 415px) {
      padding-bottom: 90px;
    }

    &:before {
      content: '';
      width: 100%;
      height: 500px;
      background: url('~@/assets/img/detail_azul_rodape.png') no-repeat top center / cover;
      position: absolute;
      bottom: 0;
    }
  }

  &-article {
    max-width: 883px;
    margin: 0 auto;
    padding: 30px 50px 100px 50px;
    position: relative;
    z-index: 1;
    background-color: #FFF;

    @media (max-width: 510px) {
      padding: 30px 20px 50px 20px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;

      &-logo {
        @media (max-width: 600px) {
          width: 130px;
        }
      }

      &-cabecalho {
        font-weight: bold;
        width: 100%;
        text-align: right;
        margin-left: 60px;
        border-bottom: 1px solid #000;
        font-size: 14px;
        text-transform: uppercase;

        @media (max-width: 600px) {
          font-size: 12px;
          margin-left: 20px;

        }
      }
    }

    &-wrapper {
      max-height: 400px;
      overflow-y: auto;
      padding: 20px 10px 20px 0;
      margin: 20px 0;
      text-align: justify;

      &::-webkit-scrollbar-track {
        background-color: transparent !important;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background: transparent !important;
      }
      &::-webkit-scrollbar-thumb {
        background: #1cb4ff;
        border-radius: 25px;
      }

      dd {
        margin-left: 15px;

        &.nivel3 {
          margin-left: 53px;
          padding-left: 46px;
        }
      }

      dd, dt {
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;

        b {
          position: absolute;
          left: 0;
        }
      }

      dt {
        padding-left: 25px;
      }

      ol {
        &.nivel1 {
          margin-left: 50px;
          margin-bottom: 20px;
        }

        &.nivel2 {
          margin-left: 40px;
          margin-bottom: 20px;

        }
      }

      li {
        dd {
          margin-bottom: 0;
          margin-left: 0px;
          padding-left: 20px;

          span {
            position: absolute;
            left: 0;
          }
        }
      }
    }


    &-btn {
      background-image: linear-gradient(to right, #1cd3ff 24%, #1cb4ff);
      font-size: 14px;
      font-weight: bold;
      color: #FFF;
      padding: 10px 20px;
      border-radius: 25px;
      text-decoration: none;
      display: inline-block;
      margin-top: 50px;
    }


    h1 {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;
      line-height: 1.2;
      margin-bottom: 30px;
      font-size: 18px;
    }



  }

  &-footer {
    height: 390px;
    position: absolute;
    width: 100%;
    bottom: 0;
    &-maozinha {
      position: absolute;
      z-index: 3;
      right: 840px;
      bottom: -6px;

      @media (max-width: 1130px) {
        right: 700px;
        width: 190px;
      }

      @media (max-width: 910px) {
        right: 500px;
      }

      @media (max-width: 715px) {
        right: 280px;
        width: 110px;
      }

      @media (max-width: 415px) {
        right: 185px;
        width: 85px;
      }
    }

    &-fundo {
      position: absolute;
      right: 0px;
      bottom: -6px;
      z-index: 2;

      @media (max-width: 1130px) {
        width: 710px;
      }

      @media (max-width: 910px) {
        width: 510px;
      }

      @media (max-width: 715px) {
        width: 300px;
      }

      @media (max-width: 415px) {
        width: 200px;
      }
    }
  }
}
</style>